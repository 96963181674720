@import url('https://fonts.googleapis.com/css2?family=Inter:wght@400;500;600;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@400;500;700&display=swap');

html,
body,
#root {
  overflow-x: hidden;
}

body {
  position: relative;
  margin: 0;
  font-family: 'Roboto', sans-serif;
  font-size: 14px;
  line-height: 19px;
  color: #122645;
  font-weight: normal;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  min-height: 100vh;
  background-color: #122645;
}

ul {
  margin: 0;
  padding: 0;
  list-style: none;
}

*:focus {
  outline: none;
}

h3 {
  font-size: 32px;
  line-height: 38px;
  margin: 0 0 24px;
  font-weight: 500;
}

h4 {
  font-size: 24px;
  line-height: 28px;
  font-weight: 600;
  margin: 0 0 28px;
}

h5 {
  font-size: 20px;
  line-height: 23px;
  margin: 12px 0 8px;
}

h6 {
  font-size: 16px;
  line-height: 19px;
  font-weight: 600;
  margin: 0 0 4px;
}


@media (min-width: 768px) {
  body {
    font-size: 16px;
    line-height: 24px;
  }

  h3 {
    font-size: 40px;
    line-height: 47px;
    margin: 0 0 28px;
    font-weight: 600;
  }

  h4 {
    font-size: 32px;
    line-height: 38px;
    margin: 0 0 28px;
  }

  h5 {
    font-size: 24px;
    line-height: 28px;
    margin: 12px 0 8px;
    font-weight: 500;
  }

  h6 {
    font-size: 18px;
    line-height: 21px;
    font-weight: 500;
    margin: 0 0 4px;
  }
}

@media (min-width: 1024px) {
  body {
    font-size: 18px;
    line-height: 28px;
  }
}
